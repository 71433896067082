import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import { TextField } from 'formik-material-ui';
import { Link } from 'gatsby-theme-material-ui';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { getOr } from 'lodash/fp';

import CustomInputField from '../custom/CustomInputField';
import { SignInValidation } from './validation';
import { auth, alert, org } from '../../state';

const SignInForm = ({ login, setAlert, setUser, getOrgDetails }) => {
  return (
    <Formik
      validationSchema={SignInValidation}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, actions) => {
        const result = await login(values);
        const payload = getOr('', ['payload'], result);
        const role = getOr('', ['payload', 'role'], result);
        const token = getOr('', ['payload', 'jwtToken'], result);
        const error = getOr('', ['error', 'message'], result);

        if (error) {
          setAlert({ message: error, severity: 'error' });
          actions.setErrors({ email: ' ', password: ' ' });
        }
        if (!error && role !== 'OrgMember') {
          setAlert({
            message: 'This login is intended for nonprofit organizations only',
            severity: 'error',
          });
          setUser({});
        }
        if (role === 'OrgMember' && !error && token) {
          await getOrgDetails(payload);
          navigate('/dashboard');
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                type="email"
                label="E-mail"
                name="email"
                variant="outlined"
              />
            </Box>
            <Box>
              <Field
                component={TextField}
                fullWidth
                required
                type="password"
                label="Password"
                name="password"
                variant="outlined"
              />
            </Box>
            <Box align="right" pb={2}>
              <Link to="/forgot-password">
                <Typography variant="caption" color="textSecondary">
                  Forgot password?
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
                size="large"
                // style={{backgroundColor: '#EE6C4D'}}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Sign In'}
              </Button>
            </Box>
            <Box align="center">
              <Link to="/register">
                <Typography variant="caption" color="textSecondary">
                  New to Pintuna? <b>Register</b>.
                </Typography>
              </Link>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

SignInForm.propTypes = {
  login: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  getOrgDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(auth.actions.login(payload)),
  setUser: (payload) => dispatch(auth.actions.setUser(payload)),
  setAlert: (payload) => dispatch(alert.actions.open(payload)),
  getOrgDetails: (payload) => dispatch(org.actions.getOrgDetails(payload)),
});

export default connect(null, mapDispatchToProps)(SignInForm);
