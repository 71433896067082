import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Link } from '@material-ui/core';
import { navigate } from '@reach/router';
import { getOr } from 'lodash/fp';

import CustomInputField from '../custom/CustomInputField';
import { ResetPasswordValidation } from './validation';
import { auth, alert } from '../../state';

const ResetPasswordForm = ({
  resetPassword,
  setAlert,
  user,
  forgotPassword,
  isLoading,
}) => {
  const resendCode = async () => {
    const email = { email: user.email };
    const response = await forgotPassword(email);
    const successMsg = getOr('', ['payload', 'message'], response);
    const error = getOr('', ['error', 'message'], response);
    if (error) {
      setAlert({ message: error, severity: 'error' });
    }
    setAlert({ message: successMsg, severity: 'success' });
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={ResetPasswordValidation}
      initialValues={{
        email: user.email,
        code: '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={async (values, actions) => {
        const response = await resetPassword(values);
        const successMsg = getOr('', ['payload', 'message'], response);
        const error = getOr('', ['error', 'message'], response);
        if (error) {
          setAlert({ message: error, severity: 'error' });
          actions.setSubmitting(false);
        }
        if (!error) {
          setAlert({ message: successMsg, severity: 'success' });
          navigate('/dashboard/login');
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <Box pb={0.5}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                label="Security Code"
                autoComplete="off"
                name="code"
                variant="outlined"
              />
            </Box>
            <Box align="right" pb={2}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                type="button"
                variant="caption"
                color="textSecondary"
                onClick={resendCode}
              >
                {isLoading && !isSubmitting ? (
                  <Box pr={4}>
                    <CircularProgress color="primary" size={14} />
                  </Box>
                ) : (
                  'Resend code?'
                )}
              </Link>
            </Box>
            <Box pb={2}>
              <Field
                component={TextField}
                fullWidth
                required
                type="password"
                label="Password"
                name="password"
                variant="outlined"
                autoComplete="off"
              />
            </Box>
            <Box pb={2}>
              <Field
                component={TextField}
                fullWidth
                required
                type="password"
                label="Confirm password"
                name="confirmPassword"
                variant="outlined"
                autoComplete="off"
              />
            </Box>
            <Box>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
                size="large"
              >
                {isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  'Reset Password'
                )}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: auth.selectors.selectUser(state),
  isLoading: auth.selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (payload) => dispatch(auth.actions.forgotPassword(payload)),
  resetPassword: (payload) => dispatch(auth.actions.resetPassword(payload)),
  setAlert: (payload) => dispatch(alert.actions.open(payload)),
  resendCode: (payload) => dispatch(auth.actions.forgotPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
