import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { getOr } from 'lodash/fp';
import CustomInputField from '../custom/CustomInputField';
import { RegistrationValidation } from './validation';
import { auth, alert } from '../../state';

const RegistrationForm = ({ register, setAlert, setUser }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={RegistrationValidation}
      initialValues={{
        firstName: '',
        lastName: '',
        orgName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: true,
      }}
      onSubmit={async (values, actions) => {
        const email = { email: values.email };
        const response = await register(values);
        const successMsg = getOr('', ['payload', 'message'], response);
        const error = getOr('', ['error', 'message'], response);
        if (error) {
          setAlert({ message: error, severity: 'error' });
          actions.setSubmitting(false);
        }
        if (!error) {
          setUser(email);
          setAlert({ message: successMsg, severity: 'success' });
          navigate('/verify-registration');
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <Box pb={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    required
                    label="First Name"
                    name="firstName"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInputField
                    customInput={Field}
                    component={TextField}
                    capitalize
                    fullWidth
                    required
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                fullWidth
                required
                capitalize
                label="Nonprofit Name"
                name="orgName"
                variant="outlined"
              />
            </Box>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                label="E-mail"
                name="email"
                variant="outlined"
                helperText="* We recommend using an e-mail associated with this organization"
              />
            </Box>
            <Box pb={2}>
              <Field
                component={TextField}
                fullWidth
                required
                type="password"
                label="Password"
                name="password"
                variant="outlined"
              />
            </Box>
            <Box pb={2}>
              <Field
                component={TextField}
                fullWidth
                required
                type="password"
                label="Confirm password"
                name="confirmPassword"
                variant="outlined"
              />
            </Box>
            <Box>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                size="large"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Register'}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

RegistrationForm.propTypes = {
  register: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  register: (payload) => dispatch(auth.actions.register(payload)),
  setUser: (payload) => dispatch(auth.actions.setUser(payload)),
  setAlert: (payload) => dispatch(alert.actions.open(payload)),
});

export default connect(null, mapDispatchToProps)(RegistrationForm);
